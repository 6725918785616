import { parseUserFromFirestore } from "./user";
import { useEffect } from "react";
import firebase from "firebase/app";
import { db } from "@/libs/firebase";
import { Room, Music, RoomType } from "@/types/room";
import { User } from "@/types/user";

export const getUserRooms = async (userId: string): Promise<Room[]> => {
  const result = await db
    .collection("rooms")
    .where("owner", "==", userId)
    .get();
  return result.docs.map((doc) => parseRoomFromFirebase(doc)!);
};

export const isRoomOwner = (userId: string, room: Room) =>
  userId === room.owner;

export const subscribeToRoomUpdates = (roomId: string, cb: Function) =>
  db
    .collection("rooms")
    .doc(roomId)
    .onSnapshot((doc) => {
      cb(doc);
    });

export const findRoomById = async (id: string) => {
  const roomDoc = await db.collection("rooms").doc(id).get();
  return parseRoomFromFirebase(roomDoc);
};

export const createRoom = async (id: string, user: User): Promise<Room> => {
  const newRoomRef = db.collection("rooms").doc(id);
  if ((await newRoomRef.get()).exists) {
    throw Error("room already exists");
  }
  const now = new Date();
  const newRoom: Omit<Room, "id" | "maxGuests" | "tokenPrice"> = {
    owner: user.id,
    guests: [user.id],
    playlist: [],
    createdDate: now,
    modifiedDate: now,
    playerState: {
      manager: user.id,
      secondsElapsed: 0,
      paused: true,
      listeners: [user.id],
    },
    type: "flat",
  };
  await newRoomRef.set(newRoom);
  return {
    ...newRoom,
    id,
    maxGuests: user.plan === "pro" ? 20 : 4,
    tokenPrice: 0,
  };
};

export const addMusicToPlaylist = async (
  currentRoom: Room,
  music: Music,
  currentUser?: User
) => {
  const newPlaylist = [...currentRoom.playlist];
  newPlaylist.push({
    ...music,
    owner: currentUser ? currentUser.id : null,
    upVotes: currentUser ? [currentUser.id] : [],
    skipVotes: [],
    addedDate: new Date(),
  });
  await db
    .collection("rooms")
    .doc(currentRoom.id!!)
    .update({
      playlist: sortPlaylist(newPlaylist),
      modifiedDate: new Date(),
    });
};

export const addPlaylistToPlaylist = async (
  currentRoom: Room,
  musics: Music[],
  currentUser?: User
) => {
  const newPlaylist = [
    ...currentRoom.playlist.filter(
      (music, idx) => idx === 0 || music.upVotes.length > 0
    ),
  ];
  await db
    .collection("rooms")
    .doc(currentRoom.id)
    .update({
      playlist: sortPlaylist(
        newPlaylist.concat(
          musics.map((music, idx) => ({
            ...music,
            owner: currentUser ? currentUser.id : null,
            upVotes: [],
            skipVotes: [],
            addedDate: new Date(new Date().getTime() + (idx + 1) * 60000),
          }))
        )
      ),
      modifiedDate: new Date(),
    });
};

export const removeMusicFromPlaylist = (currentRoom: Room, musicID: string) => {
  const musicToRemove =
    currentRoom.playlist[
      currentRoom.playlist.findIndex((music) => music.youtubeId === musicID)
    ];
  return db
    .collection("rooms")
    .doc(currentRoom.id)
    .update({
      modifiedDate: new Date(),
      playlist: firebase.firestore.FieldValue.arrayRemove(musicToRemove),
    });
};

export const upVoteMusic = (
  currentUserId: string,
  currentRoom: Room,
  music: Music
) => {
  const playlist = [...currentRoom.playlist];
  const musicIndex: number = playlist.findIndex(
    (musicItem) => musicItem.youtubeId === music.youtubeId
  );
  const musicToUpVote: Music = playlist.splice(musicIndex, 1)[0];
  const userIndex = musicToUpVote.upVotes!!.indexOf(currentUserId);
  if (userIndex === -1) {
    musicToUpVote.upVotes!!.push(currentUserId);
  } else {
    musicToUpVote.upVotes!!.splice(userIndex, 1);
  }
  playlist.push(musicToUpVote);
  const sortedPlaylist = sortPlaylist(playlist);
  return db.collection("rooms").doc(currentRoom.id!!).set(
    {
      playlist: sortedPlaylist,
      modifiedDate: new Date(),
    },
    { merge: true }
  );
};

export const skipVoteMusic = (
  currentUserId: string,
  currentRoom: Room,
  music: Music
) => {
  const playlist = [...currentRoom.playlist];
  const musicIndex: number = playlist.findIndex(
    (musicItem) => musicItem.youtubeId === music.youtubeId
  );
  const musicToSkipVote: Music = playlist[musicIndex];
  if (!musicToSkipVote) return;
  const userIndex = musicToSkipVote.skipVotes.indexOf(currentUserId);
  if (userIndex === -1) {
    musicToSkipVote.skipVotes!!.push(currentUserId);
  } else {
    musicToSkipVote.skipVotes!!.splice(userIndex, 1);
  }
  return db.collection("rooms").doc(currentRoom.id!!).set(
    {
      modifiedDate: new Date(),
      playlist,
    },
    { merge: true }
  );
};

export const skipMusic = async (currentRoom: Room) => {
  currentRoom.playlist.splice(0, 1);
  return db
    .collection("rooms")
    .doc(currentRoom.id!!)
    .update({
      modifiedDate: new Date(),
      playlist: [...currentRoom.playlist],
      "playerState.secondsElapsed": 0,
    });
};

const sortPlaylist = (playlistToSort: Music[]) => {
  const playlist = [...playlistToSort];
  return [playlist.shift()]
    .concat(playlist.filter((music) => !music.fromLibrary).sort(musicSorter))
    .concat(playlist.filter((music) => music.fromLibrary).sort(musicSorter));
};

const musicSorter = (a: Music, b: Music) => {
  if (a.upVotes!!.length < b.upVotes!!.length) {
    return 1;
  }
  if (
    a.upVotes!!.length === b.upVotes!!.length &&
    a.addedDate!! > b.addedDate!!
  ) {
    return 1;
  }
  return -1;
};

export const userHasUpVoted = (userId: string, music: Music) => {
  return music.upVotes.indexOf(userId) !== -1;
};

export const userHasSkipVoted = (userId: string, music: Music) => {
  return music.skipVotes.indexOf(userId) !== -1;
};

export const playlistHasMusics = (playlist: Music[]) => {
  return playlist.length !== 0;
};

export const parseRoomFromFirebase = (doc: any): Room | undefined => {
  if (!doc.exists) {
    return;
  }
  return {
    id: doc.id,
    owner: doc.data()!.owner,
    guests: doc.data()!.guests,
    playlist: doc.data()!.playlist.map((music: any) => {
      if (typeof music.addedDate === "string") {
        music.addedDate = new Date(music.addedDate.toString());
      } else {
        music.addedDate = music.addedDate.toDate();
      }
      return { ...music };
    }),
    createdDate: doc.data()!.createdDate.toDate(),
    modifiedDate: doc.data()!.modifiedDate?.toDate() ?? new Date(),
    playerState: doc.data()!.playerState ?? {
      manager: doc.data()!.owner,
      secondsElapsed: 0,
      paused: false,
      listeners: [doc.data()!.owner],
    },
    maxGuests: doc.data()!.maxGuests ?? 4,
    tokenPrice: doc.data()!.tokenPrice ?? 0,
    type: doc.data()!.type ?? "flat",
    name: doc.data()!.name,
  };
};

export const joinRoom = async (
  roomId: string,
  userId: string
): Promise<Room | undefined> => {
  if (!roomId) return;
  const roomRef = db.collection("rooms").doc(roomId);
  const room = parseRoomFromFirebase(await roomRef.get());
  if (!room) {
    return;
  }
  const twoHoursBefore = new Date();
  twoHoursBefore.setHours(twoHoursBefore.getHours() - 2);
  const roomIsInactive = room.modifiedDate <= twoHoursBefore;
  if (roomIsInactive) {
    await flushOtherGuests(room.id, userId, room.type);
    room.guests = [userId];
    room.playerState = {
      ...room.playerState,
      listeners: [userId],
      manager: userId,
    };
    return room;
  } else {
    const newPlayerState = { ...room.playerState };
    let newGuests = [...room.guests];
    if (room.guests.length >= room.maxGuests) {
      if (room.owner === userId) {
        if (!room.guests.includes(userId)) {
          const removeLastGuest = () => newGuests.splice(0, 1);
          removeLastGuest();
        }
      } else {
        throw new Error("room is full");
      }
    }
    if (room.owner === userId && room.playerState.manager !== userId) {
      newPlayerState.manager = userId;
    }
    if (!newPlayerState.listeners.includes(userId) && room.owner === userId) {
      newPlayerState.listeners.push(userId);
    }
    if (!room.guests.includes(userId)) {
      newGuests.push(userId);
    }
    const updatedFields = {
      modifiedDate: new Date(),
      guests: newGuests,
      playerState: newPlayerState,
    };
    newGuests.reverse();
    await roomRef.update(updatedFields);
    return {
      ...room,
      ...updatedFields,
    };
  }
};

export const updateGuests = (roomId: string, guests: string[]) =>
  db.collection("rooms").doc(roomId).update({ guests });

export const getParticipantsInfo = async (
  guestsId: string[]
): Promise<User[]> =>
  (
    await Promise.all(
      guestsId.map((guestId) => db.collection("users").doc(guestId).get())
    )
  )
    .map((guestDoc) => parseUserFromFirestore(guestDoc))
    .filter((guest) => guest !== undefined) as User[];

export const removeGuest = (guestId: string, room: Room) => {
  const newPlayerState = { ...room.playerState };
  newPlayerState.listeners = newPlayerState.listeners.filter(
    (listener) => listener !== guestId
  );
  if (newPlayerState.manager === guestId) {
    newPlayerState.manager =
      newPlayerState.listeners.length > 0
        ? newPlayerState.listeners[0]
        : room.owner;
  }
  return db
    .collection("rooms")
    .doc(room.id)
    .update({
      modifiedDate: new Date(),
      guests: firebase.firestore.FieldValue.arrayRemove(guestId),
      playerState: { ...newPlayerState },
    });
};

export const flushOtherGuests = (
  roomId: string,
  currentUserId: string,
  roomType: RoomType
) =>
  db
    .collection("rooms")
    .doc(roomId)
    .update({
      guests: [currentUserId],
      "playerState.listeners": roomType === "bar" ? [] : [currentUserId],
      "playerState.manager": roomType === "bar" ? undefined : currentUserId,
    });

export const useRoomGuard = (
  { currentRoom, currentUser }: { currentRoom?: Room; currentUser?: User },
  done: () => void
) =>
  useEffect(() => {
    if (!currentRoom || !currentUser) return done();
    removeGuest(currentUser.id, currentRoom).then(done);
  }, []);

export const deleteRoom = (roomId: string) =>
  db.collection("rooms").doc(roomId).delete();
